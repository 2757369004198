/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import styled from 'styled-components'
import Hero from '../components/elements/Hero'
import Vacancys from '../components/elements/Vacancys'

export const pageQuery = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      content
      pageVacancys {
        bannervacancys {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const Title = styled.h2`
  font-family: ${(props) => props.theme.font.family.secondary};
`

const PageTemplate = ({
  data: {
    page: { pageVacancys, seo },
  },
}) => (
  <Layout subFooter>
    {seo && <SEO seo={seo} />}
    <Hero title="Vacatures" src={pageVacancys.bannervacancys.image} />

    <Vacancys />
    {/* <div className="container">
      <div className="row py-5">
        <div className="col-lg-10">
          <Title>VACATURES</Title>
          <p>
            Momenteel hebben we geen vacatures beschikbaar maar denk jij van
            toegevoegde waarde te kunnen zijn voor C3 Staalframebouw, stuur dan
            vrijblijvend je cv en motivatie naar{' '}
            <a href="mailto:info@c3staalframebouw.nl">
              info@c3staalframebouw.nl
            </a>
          </p>
        </div>
      </div>
    </div> */}
  </Layout>
)

export default PageTemplate
