/* eslint-disable react/function-component-definition */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { Link } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'

import StreamModal from '../StreamModal'

import BoxDefault from './BoxDefault'

const Card = styled(Link)`
  line-height: 1.2;
  position: relative;
  display: block;

  p {
    margin-bottom: 0;
  }

  a {
    font-size: ${(props) => props.theme.font.size.m};
    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.color.text.contrast};
    }
  }

  &.with-offset {
    padding-right: 7.5%;

    .box {
      margin-top: -25px;
      margin-left: 7.5%;
    }
  }

  @media (max-width: 1199.98px) {
  }

  @media (max-width: 991.98px) {
    margin-bottom: 5px;
  }

  @media (max-width: 767.98px) {
  }

  @media (max-width: 575.98px) {
  }
`

// const StreamImage = styled.img`
//   width: 200px;
//   position: absolute;
//   top: 0;
//   z-index: 2;
// `

const Title = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 60px;
  overflow: hidden;
  line-height: 100% !important;
  margin: 0 !important;

  & i {
    font-style: normal;
  }
`

const Image = styled(Plaatjie)`
  height: 284px;
  width: 100%;
`

const ProjectCard = ({ src, title, link, livestream, location, offset }) => {
  let linkType
  if (link.href) {
    linkType = <a href={link.href}>{link.title}</a>
  } else {
    linkType = <Link to={link.to}>{link.title}</Link>
  }

  return (
    <Card
      to={link.to || link.href}
      className={`d-flex flex-column ${offset ? 'with-offset' : ''}`}
    >
      {livestream && livestream.iframe ? (
        <StreamModal image={livestream.image} link={livestream} />
      ) : (
        <p />
      )}
      <Image image={src} />
      <BoxDefault className="box" big>
        <Title className="text-uppercase font-family-secondary font-size-ll text-white font-weight-500 mb-3">
          {parse(title)}{' '}
          {location !== null && (
            <>
              - <i className="font-weight-200">{parse(location)}</i>
            </>
          )}
        </Title>
        <div
          className="d-flex flex-wrap align-items-center justify-content-end pr-3 mt-3"
          style={{ textDecoration: 'underline', color: '#D50032' }}
        >
          {/* {livestream && livestream.iframe ? (
            <StreamModal content="Bekijk livestream >" link={livestream} />
          ): (
              <p></p>
          )} */}
          {linkType}
        </div>
      </BoxDefault>
    </Card>
  )
}

ProjectCard.propTypes = {
  src: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string,
    to: PropTypes.string,
  }),
  location: PropTypes.string.isRequired,
  offset: PropTypes.bool,
}

export default ProjectCard
