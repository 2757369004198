import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import styled from 'styled-components'
import ProjectCard from './ProjectCard'

// Third Party
const StyledVacancyOverview = styled.div``

const Title = styled.h2`
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: 75px;

  @media (max-width: 991px) {
    font-size: 35px;
  }
`

function VacancyOverview({ className }) {
  const {
    posts: { nodes: posts },
  } = useStaticQuery(graphql`
    {
      posts: allWpVacature {
        nodes {
          databaseId
          title
          uri
          vacancyrecap {
            overview {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 2000, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledVacancyOverview
      className={`container py-lg-5 py-3 ${className ? `${className}` : ``}`}
    >
      <Title>VACATURES</Title>
      <div className="row">
        {posts.map((p) => (
          <div key={p.wordpress_id} className="col-md-6 mb-2 mb-lg-4">
            <ProjectCard
              src={p.vacancyrecap.overview.image}
              title={p.title}
              link={{ to: p.uri, title: 'Bekijk bericht >' }}
              location={null}
            />
          </div>
        ))}
      </div>
    </StyledVacancyOverview>
  )
}

export default VacancyOverview
